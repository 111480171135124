import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import analysisCard from "../ToolsCard.vue";
import moment from "moment";

import Button from "@/components/button";
import Input from "@/components/input";
import Table from "@/components/table";
import Tab from "@/components/tab";
import NeoTabsSwitch from "@/components/tabs-switch";
import Loader from "@/components/loader";
import DropdownAnalysis from "@/components/dropdown-analysis";
import VueMultiselect from "@/components/vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import Pagination from "@/components/pagination";
import axios from "../../../../axios/osint.js";
import SaasAxios from "@/axios";
import {mapGetters, mapMutations, mapActions} from "vuex";
import neoSwitch from "@/components/toggle-switch";
import {getMonitoring} from "@/utils/functions";
import {monitoringSearchData, getBackgroundStatus, getResultsID} from "@/utils/monitoringTools.js";
import toolsHelper from "@/mixins/toolsHelper";
import {setUsernamesDropdownData, convertToCSV, csvFileName} from "@/utils/functions";
import NeoAnalyse from "@/components/analyse";
import JsonCSV from "vue-json-csv";
import {uniqBy, get} from "lodash";
import NlpAnalysisHighlighting from "@/components/nlp-analysis-highlighting";
const ImagePlaceholder = () => import("@/components/image-placeholder");
import {DateTime} from "luxon";
import {startCase} from "lodash";

export default {
    name: "neo-twitter",
    mixins: [toolsHelper],
    components: {
        "neo-input": Input,
        "neo-button": Button,
        "neo-table": Table,
        "neo-tab": Tab,
        "neo-tabs-switch": NeoTabsSwitch,
        "neo-pagination": Pagination,
        "font-awesome-icon": FontAwesomeIcon,
        "neo-loader": Loader,
        "neo-dropdown-analysis": DropdownAnalysis,
        VueMultiselect,
        "analysis-card": analysisCard,
        NeoAnalyse,
        neoSwitch,
        "download-csv": JsonCSV,
        "nlp-analysis-highlighting": NlpAnalysisHighlighting,
        ImagePlaceholder,
    },
    props: {
        osintGraphDrawerOpen: {
            type: Boolean,
            default: false,
        },
        toolData: {
            type: Array,
            default: () => [],
        },
        tool: {
            type: String,
            default: "",
        },
        toolSearchQueries: Array,
    },
    data() {
        return {
            twitterUsers: [],
            loaderController: {
                details: {
                    loading: false,
                    success: false,
                    filterLoading: false,
                    filterSuccess: false,
                },
                tweets: {
                    loading: false,
                    success: false,
                    filterLoading: false,
                    filterSuccess: false,
                },
                followers: {
                    loading: false,
                    success: false,
                    filterLoading: false,
                    filterSuccess: false,
                },
                friends: {
                    loading: false,
                    success: false,
                    filterLoading: false,
                    filterSuccess: false,
                },
            },
            alertMessage: {
                success: null,
                error: null,
            },
            isSearchClicked: false,
            case_id: "",
            inputValue: {
                text: "Username",
                value: "Username",
            },
            inputOptions: [
                {
                    group: "Field Type",
                    categories: [
                        {
                            text: "Username",
                            value: "Username",
                        },
                        {
                            text: "Hashtag",
                            value: "Hashtag",
                        },
                        {
                            text: "Query",
                            value: "Query",
                        },
                    ],
                },
            ],
            userDetails: {},
            allTweets: [],
            allFollowers: [],
            allFriends: [],
            allUsers: [],
            userDetailsMonitoring: [],
            filters: {},
            isFollowerHeader: false,
            isFriendHeader: false,
            headers: [
                {
                    text: "Tweets",
                    value: "tweet",
                },
                {
                    text: "Author",
                    value: "author",
                },
                {
                    text: "Media",
                    value: "media",
                },
                {
                    text: "Location",
                    value: "location",
                },
                {
                    text: "Retweet",
                    value: "retweet_count",
                },
                {
                    text: "Likes",
                    value: "favorite_count",
                },
                {
                    text: "Time",
                    value: "created_at",
                },
            ],
            csvData: {
                friends: [],
                followers: [],
                tweets: [],
                users: [],
            },
            followerHeaders: [
                {
                    text: "",
                    value: "profile",
                },
            ],
            friendsHeaders: [
                {
                    text: "",
                    value: "profile",
                },
            ],
            itemKey: "id",
            searchCaseFilter: "",
            followerFilter: "",
            friendsFilter: "",
            selectable: true,
            hasAction: false,
            showResult: false,
            pagination: {
                tweets: {
                    page: 1,
                    perPage: 20,
                    total_page: 1,
                },
                followers: {
                    page: 1,
                    perPage: 100,
                    total_page: 1,
                    next: "",
                },
                friends: {
                    page: 1,
                    perPage: 100,
                    total_page: 1,
                    next: "",
                },
                users: {
                    page: 1,
                    perPage: 20,
                    total_page: 1,
                    next: "",
                },
            },
            hashtag: "",
            person: "",
            searchKeyword: "",
            selectedTab: "details",
            selectedItem: null,
            tabs: [
                {
                    name: "Details",
                    value: "details",
                },
                {
                    name: "Tweets",
                    value: "tweets",
                },
            ],
            searchFollowers: "",
            searchFriends: "",
            triggerRoute: true,
            selectVal: "",
            tabName: "",
            isLoading: false,
            request_id: "",
            followersExists: true,
            friendsExists: true,
            continuationToken: "",
        };
    },
    computed: {
        ...mapGetters(["getProduct", "getSelectedToolQuery", "getMonitoringDateFilter", "getReadOnlyMode", "getResearchData", "getAllToolsData", "getAllMonitoringData", "getCaseUsernames"]),

        isMonitoringTab() {
            return this.$route.name === "monitoring";
        },

        // this.adverseResults
        checkMarkAllDone() {
            if (this.allUsers && this.allUsers.length) return this.allUsers.every((result) => result.changes_viewed);
            else return false;
        },

        checkMarkUserDetailsDone() {
            if (this.userDetailsMonitoring && this.userDetailsMonitoring.length) return this.userDetailsMonitoring.every((result) => result.changes_viewed);
            else return false;
        },

        twitterProfilesData() {
            return this.isMonitoringTab ? this.userDetailsMonitoring : [this.userDetails];
        },

        fetchAllData() {
            if (this.getResearchData.personalDetails?.length || this.getResearchData.socialProfiles?.length) {
                this.getToolData();
            }
        },

        csvFields() {
            return {
                friends: ["name", "screen_name", "location", "description", "profile_image_url"],
                followers: ["name", "screen_name", "location", "description", "profile_image_url"],
                tweets: ["full_text", "user", "entities", "place", "retweet_count", "favorite_count", "created_at"],
                users: ["id", "name", "screen_name", "description", "location", "created_at", "profile_image_url", "friends_count", "followers_count", "statuses_count"],
            };
        },

        query() {
            return this.selectedItem?.value || this.hashtag || this.person;
        },

        tweets() {
            let data = [];
            const start = (this.pagination.tweets.page - 1) * this.pagination.tweets.perPage;
            const end = this.pagination.tweets.page * this.pagination.tweets.perPage - 1;
            this.allTweets.forEach((tweet, index) => {
                if (start <= index && index <= end) {
                    data.push(tweet);
                }
            });
            data = uniqBy(data, "tweet_id");
            return data;
        },

        followers() {
            let data = [];

            if (this.searchFollowers) {
                let searchedData = this.allFollowers.filter((n) => n.name?.toLowerCase().includes(this.searchFollowers?.toLowerCase()) || n.location?.toLowerCase().includes(this.searchFollowers?.toLowerCase()) || n.screen_name?.toLowerCase().includes(this.searchFollowers?.toLowerCase()) || n.description?.toLowerCase().includes(this.searchFollowers?.toLowerCase()));
                const start = (this.pagination.followers.page - 1) * this.pagination.followers.perPage;
                const end = this.pagination.followers.page * this.pagination.followers.perPage - 1;
                searchedData.forEach((follower, index) => {
                    if (start <= index && index <= end) {
                        data.push(follower);
                    }
                });
                this.pagination.followers.total_page = parseInt(searchedData.length / this.pagination.followers.perPage, 10);
                if (searchedData.length % this.pagination.followers.perPage) {
                    this.pagination.followers.total_page += 1;
                }
            } else if (this.allFollowers) {
                const start = (this.pagination.followers.page - 1) * this.pagination.followers.perPage;
                const end = this.pagination.followers.page * this.pagination.followers.perPage - 1;
                this.allFollowers.forEach((follower, index) => {
                    if (start <= index && index <= end) {
                        data.push(follower);
                    }
                });
                this.pagination.followers.total_page = parseInt(this.allFollowers.length / this.pagination.followers.perPage, 10);
                if (this.allFollowers.length % this.pagination.followers.perPage) {
                    this.pagination.followers.total_page += 1;
                }
            }
            data = uniqBy(data, "screen_name");
            return data;
        },

        friends() {
            let data = [];

            if (this.searchFriends) {
                let searchData = this.allFriends.filter((n) => n.name?.toLowerCase().includes(this.searchFriends?.toLowerCase()) || n.location?.toLowerCase().includes(this.searchFriends?.toLowerCase()) || n.screen_name?.toLowerCase().includes(this.searchFriends?.toLowerCase()) || n.description?.toLowerCase().includes(this.searchFriends?.toLowerCase()));
                const start = (this.pagination.friends.page - 1) * this.pagination.friends.perPage;
                const end = this.pagination.friends.page * this.pagination.friends.perPage - 1;
                searchData.forEach((friend, index) => {
                    if (start <= index && index <= end) {
                        data.push(friend);
                    }
                });
                this.pagination.friends.total_page = parseInt(searchData.length / this.pagination.friends.perPage, 10);
                if (searchData.length % this.pagination.friends.perPage) {
                    this.pagination.friends.total_page += 1;
                }
            } else if (this.allFriends) {
                const start = (this.pagination.friends.page - 1) * this.pagination.friends.perPage;
                const end = this.pagination.friends.page * this.pagination.friends.perPage - 1;
                this.allFriends.forEach((friend, index) => {
                    if (start <= index && index <= end) {
                        data.push(friend);
                    }
                });
                this.pagination.friends.total_page = parseInt(this.allFriends.length / this.pagination.friends.perPage, 10);
                if (this.allFriends.length % this.pagination.friends.perPage) {
                    this.pagination.friends.total_page += 1;
                }
            }
            data = uniqBy(data, "screen_name");
            return data;
        },

        users() {
            let data = [];
            const start = (this.pagination.users.page - 1) * this.pagination.users.perPage;
            const end = this.pagination.users.page * this.pagination.users.perPage - 1;
            this.allUsers.forEach((friend, index) => {
                if (start <= index && index <= end) {
                    data.push(friend);
                }
            });
            // data = data.filter((n) => n.name?.toLowerCase().includes(this.searchFriends.toLowerCase()) || n.location?.toLowerCase().includes(this.searchFriends.toLowerCase()) || n.screen_name?.toLowerCase().includes(this.searchFriends.toLowerCase()) || n.description?.toLowerCase().includes(this.searchFriends.toLowerCase()));
            return data;
        },
    },
    watch: {
        userDetails() {
            if (this.userDetails) {
                this.$emit("searchResult", {data: this.userDetails, source: "twitter", searchType: "username"});
            }
        },
        allFollowers() {
            if (this.allFollowers.length > 0) {
                this.$emit("searchResult", {data: this.allFollowers, source: "twitter", searchType: "followers"});
            }
        },
        allFriends() {
            if (this.allFriends.length > 0) {
                this.$emit("searchResult", {data: this.allFriends, source: "twitter", searchType: "friends"});
            }
        },
        "$route.query"(to, from) {
            if (this.triggerRoute) {
                this.redirectToQuery();
            }
        },

        // hashtag() {
        //     this.showResult = false;
        // },

        // person() {
        //     this.showResult = false;
        // },

        // selectedItem: {
        //     handler(val) {
        //         this.showResult = false;
        //     },
        //     deep: true,
        // },
    },
    async mounted() {
        this.case_id = this.$route.params.id;
        this.tabName = this.$route.name;
        const dataRes = this.getToolsData(this.tabName, "twitter-tool");
        if (dataRes) this.redirectToQuery(dataRes);
        this.getToolData();
    },
    beforeDestroy() {
        this.setToolsData(this.tabName, "twitter-tool");
    },
    methods: {
        ...mapMutations(["SET_ALL_TOOLS_DATA", "SET_ALL_MONITORING_DATA"]),
        ...mapActions(["getAllCaseUsernames"]),
        async getToolData() {
            this.twitterUsers = await this.getDropdownData("username");
        },

        async getDropdownData(field) {
            let data = this.toolData.filter((user) => user.val_type === field);
            return data;
        },

        getOptionsList(list) {
            return [
                {
                    group: "Username",
                    categories: this[list],
                },
            ];
        },

        handleChecked(data) {
            let allChecked = false;
            this.$emit("updateToolData", "twitter", [...this.twitterUsers], data);
            allChecked = [...this.twitterUsers]?.length ? ([...this.twitterUsers].findIndex((n) => !n.visited) > -1 ? false : true) : false;

            this.$emit("handleChecked", allChecked);
        },

        convertToCSV(arr) {
            return convertToCSV(arr);
        },

        csvFileName(query, section) {
            return csvFileName(this.tool, query, section);
        },

        async switchTab() {
            if (this.selectedTab === "tweets" && this.isSearchClicked) {
                this.pagination.tweets.page = 1;
                this.allTweets = [];
                await this.getTweets();
                this.showResult = true;
            }
        },

        startLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = true;
        },
        successLoader(section) {
            this.loaderController[section].success = true;
            this.loaderController[section].loading = false;
        },
        resetLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = false;
        },

        async paginateTweets(pageNo) {
            this.pagination.tweets.page = pageNo;

            if (this.selectedTab === "tweets" || this.inputValue.value == "Hashtag") {
                if (pageNo === this.pagination.tweets.total_page && this.continuationToken) {
                    await this.getTweets(true);
                }
                document
                    .getElementsByClassName("twitter_tweets")[0]
                    ?.querySelector("tbody")
                    .scrollTo(0, 0);
            }
        },

        async paginateUser(pageNo) {
            this.pagination.users.page = pageNo;
            if (pageNo === this.pagination.users.total_page && this.continuationToken) {
                await this.getPerson(true);
            }
        },

        searchFollower() {
            this.pagination.followers.page = 1;
        },

        friendsSearch() {
            this.pagination.friends.page = 1;
        },

        paginateFollowers(pageNo) {
            this.pagination.followers.page = pageNo;
            if (pageNo === this.pagination.followers.total_page && this.pagination.followers.next && !this.searchFollowers) {
                this.fetchResult(true, "Followers");
            }
            document
                .getElementsByClassName("twitter_followers")[0]
                .querySelector("tbody")
                .scrollTo(0, 0);
        },

        paginateFriends(pageNo) {
            this.pagination.friends.page = pageNo;
            if (pageNo === this.pagination.friends.total_page && this.pagination.friends.next) {
                this.fetchResult(true, "Friends");
            }
            document
                .getElementsByClassName("twitter_friends")[0]
                .querySelector("tbody")
                .scrollTo(0, 0);
        },

        getCount(count) {
            return count >= 1000 ? (count / 1000).toFixed(1) + "K" : count;
        },

        getTime(item) {
            let date = new Date(item.creation_date);
            return date ? date.getDate() + "/" + (parseInt(date.getMonth(), 10) + 1) + "/" + date.getFullYear() : "";
        },

        getJoined(item) {
            let date = new Date();
            if (item.user) date = new Date(item.user.creation_date);
            else date = new Date(item.created_at);
            // let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            return date ? moment(date).format("DD MMMM YYYY, HH:mm") : "";
            // return date ? date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear() : '' + " " + date.getHours() + ":" + date.getMinutes() ;
        },

        updateFieldType() {
            this.hashtag = "";
            this.person = "";
            this.selectedItem = null;
            this.searchKeyword = "";
            this.continuationToken = null;
            this.allUsers = [];
            this.allFriends = [];
            this.allFollowers = [];
            this.allTweets = [];
            this.userDetailsMonitoring = [];
            this.userDetails = null;
            this.selectVal = "";
            this.csvData = {
                friends: [],
                followers: [],
                tweets: [],
                users: [],
            };
            this.pagination = {
                tweets: {
                    page: 1,
                    perPage: 20,
                    total_page: 1,
                },
                followers: {
                    page: 1,
                    perPage: 100,
                    total_page: 1,
                    next: "",
                },
                friends: {
                    page: 1,
                    perPage: 100,
                    total_page: 1,
                    next: "",
                },
                users: {
                    page: 1,
                    perPage: 20,
                    total_page: 1,
                    next: "",
                },
            };
            this.showResult = false;
            this.isSearchClicked = false;
            if (this.inputValue.value === "Hashtag") this.selectedTab = "tweets";
            else this.selectedTab = "details";
        },

        setInput() {
            this.searchKeyword = this.selectedItem.value;
            // this.search();
        },

        setTheCustomInput(value) {
            this.searchKeyword = value;
            this.selectedItem = {
                value: value,
            };
            // this.search();
        },

        manualSearch() {
            this.triggerRoute = false;
            this.search();
        },

        async search() {
            this.isSearchClicked = true;
            this.showResult = false;
            this.userDetails = null;
            this.allUsers = [];
            this.allFriends = [];
            this.allFollowers = [];
            this.allTweets = [];
            this.userDetailsMonitoring = [];
            this.csvData = {
                friends: [],
                followers: [],
                tweets: [],
                users: [],
            };
            this.pagination = {
                tweets: {
                    page: 1,
                    perPage: 20,
                    total_page: 1,
                },
                followers: {
                    page: 1,
                    perPage: 100,
                    total_page: 1,
                    next: "",
                },
                friends: {
                    page: 1,
                    perPage: 100,
                    total_page: 1,
                    next: "",
                },
                users: {
                    page: 1,
                    perPage: 20,
                    total_page: 1,
                    next: "",
                },
            };
            if (this.searchKeyword && this.inputValue.value == "Username") {
                // IN MONITORING TAB FETCH MONITORING QUERY RESULTS
                if (this.isMonitoringTab) {
                    await this.monitoringSearch(this.inputValue.value);
                    return;
                }
                this.$emit("searched", {data: {username: this.searchKeyword}, source: "twitter"});
                this.getUserDetails();
                await this.setSearchedTweetUser(this.searchKeyword);
                if (this.selectedTab === "tweets") {
                    await this.getTweets();
                    this.showResult = true;
                }
                this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "twitter-tool"});
                this.triggerRoute = true;
            } else if (this.hashtag && this.inputValue.value == "Hashtag") {
                // IN MONITORING TAB FETCH MONITORING QUERY RESULTS
                if (this.isMonitoringTab) {
                    await this.monitoringSearch(this.inputValue.value);
                    return;
                }
                this.$emit("searched", {data: {hashtag: this.hashtag}, source: "twitter"});
                await this.getTweets();
                this.showResult = true;
                this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "twitter-tool"});
                this.triggerRoute = true;
            } else if (this.hashtag && this.inputValue.value == "Hashtag" && hashtagRegex.test(this.hashtag)) {
                // IN MONITORING TAB FETCH MONITORING QUERY RESULTS
                if (this.isMonitoringTab) {
                    await this.monitoringSearch(this.inputValue.value);
                    return;
                }
                this.$emit("searched", {data: {hashtag: this.hashtag}, source: "twitter"});
                await this.getTweets();
                this.showResult = true;
                this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "twitter-tool"});
                this.triggerRoute = true;
            } else if (this.person && this.inputValue.value == "Query") {
                // IN MONITORING TAB FETCH MONITORING QUERY RESULTS
                this.continuationToken = null;
                if (this.isMonitoringTab) {
                    await this.monitoringSearch(this.inputValue.value);
                    return;
                }
                this.$emit("searched", {data: {search_query_twitter: this.person}, source: "twitter"});
                await this.getPerson();
                this.showResult = true;
                this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "twitter-tool"});
                this.triggerRoute = true;
            } else {
                this.$toast.error("Please enter a valid input");
            }
        },

        async getUserDetails() {
            this.startLoader("details");
            this.userDetails = {};
            let data = {
                username: this.searchKeyword,
            };

            const response = await axios.post("/twitter/user", data, {
                headers: {
                    "x-tool-name": this.$route.params.toolName,
                    "x-visited": true,
                },
            });
            try {
                if (response && response.data && response.data.data && response.data.data.account_status?.toLowerCase() !== "suspended") {
                    this.userDetails = response.data.data;
                    this.showResult = true;
                    let address = this.userDetails.location;
                    // The location of Uluru
                    let geocoder = new google.maps.Geocoder();
                    if (address) {
                        geocoder.geocode({address: address}, function(results, status) {
                            if (results.length > 0) {
                                const lat = results[0].geometry.location.lat();
                                const lng = results[0].geometry.location.lng();
                                const uluru = {lat: lat, lng: lng};
                                // The map, centered at Uluru
                                const map = new google.maps.Map(document.getElementById("googleMap"), {
                                    zoom: 12,
                                    center: uluru,
                                });
                                // The marker, positioned at Uluru
                                const marker = new google.maps.Marker({
                                    position: uluru,
                                    map: map,
                                });
                            }
                        });
                    }
                    this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "twitter-tool"});
                } else if (response && response.data && response.data.status == false) {
                    this.$toast.error(response.data.message);
                } else if (response && response.data && response.data.data.account_status?.toLowerCase() === "suspended") {
                    this.$toast.error("Account Suspended");
                } else if (response) {
                    this.$toast.error("No data found");
                }

                this.request_id = response?.data?.query_id;
                this.redirectTo();
                this.resetLoader("details");
            } catch (error) {
                this.resetLoader("details");
                let message = error.message;
                this.$toast.error(message);
            }
        },
        async setSearchedTweetUser(searchKeyword) {
            const data = {
                value: searchKeyword,
                platform: "twitter",
                val_type: this.selectedItem?.val_type || "username",
                visited: true,
            };
            let url = `research-methodology/${this.case_id}/tools/profiles`;
            let isSuccess = await setUsernamesDropdownData(url, data);
            // if (isSuccess) {
            let dataItem = this[`twitterUsers`].find((dataItem) => {
                return dataItem.value === searchKeyword;
            });
            if (dataItem) dataItem["visited"] = true;
            else if (data?.value) {
                this[`twitterUsers`].push(data);
                this.$emit("addCustomData", data);
            }
            this.handleChecked(data);
            // }
        },
        async getTweets(isNext) {
            if (this.searchKeyword || this.hashtag) {
                this.startLoader("tweets");
                let data = {
                    username: this.searchKeyword,
                    start: this.continuationToken,
                };

                let url = "/twitter/user/tweets";

                if (this.hashtag) {
                    data = {
                        hashtag: this.hashtag,
                        start: this.continuationToken,
                    };
                    url = "/twitter/user/hashtag";
                }

                const response = await axios.post(url, data, {
                    headers: {
                        "x-tool-name": this.$route.params.toolName,
                        "x-visited": true,
                    },
                });
                try {
                    if (response && response.data && response.data.data?.length >= 0) {
                        if (response.data.continuation_token) this.continuationToken = response.data.continuation_token;
                        else this.continuationToken = "";
                        if (this.hashtag) response.data.data.map((el) => (el.full_text = el.text));
                        if (isNext) {
                            this.allTweets = this.allTweets.concat(response.data.data);
                        } else {
                            this.allTweets = [];
                            this.allTweets = response.data.data;
                        }

                        this.csvData.tweets = this.convertToCSV(this.allTweets);

                        this.pagination.tweets.total_page = parseInt(this.allTweets.length / this.pagination.tweets.perPage, 10);

                        if (this.allTweets.length % this.pagination.tweets.perPage) {
                            this.pagination.tweets.total_page += 1;
                        }
                        if (this.continuationToken) this.paginateTweets(this.pagination.tweets.page);
                        this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "twitter-tool"});
                    } else {
                        this.continuationToken = "";
                        if (response) this.$toast.error("Something went wrong. Please try again after some time");
                    }

                    // this.request_id = response?.data?.query_id;
                    this.redirectTo();
                    this.resetLoader("tweets");
                } catch (error) {
                    this.resetLoader("tweets");
                    let message = error.message;
                    this.$toast.error(message);
                }
            } else {
                this.$toast.error("Please enter a valid input");
            }
        },
        async getPerson(isNext) {
            if (this.allUsers.length === 0) {
                this.continuationToken == null;
            }
            if (this.person) {
                let data = {
                    query: this.person,
                    start: this.continuationToken,
                };
                let url = "/twitter/user/search";

                const response = await axios.post(url, data, {
                    headers: {
                        "x-tool-name": this.$route.params.toolName,
                        "x-visited": true,
                    },
                });
                try {
                    if (response && response.data && response.data.data?.length >= 0) {
                        if (response.data.continuation_token) this.continuationToken = response.data.continuation_token;
                        else this.continuationToken = "";
                        if (isNext) {
                            this.allUsers = this.allUsers.concat(response.data.data);
                        } else {
                            this.allUsers = [];
                            this.allUsers = response.data.data;
                        }
                        this.allUsers = this.allUsers.filter((value, index, self) => index === self.findIndex((t) => t.user.user_id === value.user.user_id));
                        this.$emit("searchResult", {data: this.allUsers, source: "twitter", searchType: "query"});
                        this.csvData.users = this.convertToCSV(this.allUsers);

                        this.pagination.users.total_page = parseInt(this.allUsers.length / this.pagination.users.perPage, 10);
                        if (this.allUsers.length % this.pagination.users.perPage) {
                            this.pagination.users.total_page = this.pagination.users.total_page + 1;
                        }
                        if (this.continuationToken) this.paginateUser(this.pagination.users.page);
                        this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "twitter-tool"});
                    } else if (response) {
                        this.$toast.error("No data found");
                    }
                    this.request_id = response?.data?.query_id;
                    this.redirectTo();
                } catch (error) {
                    // let message = error.message;
                    // this.$toast.error(message);
                }
            } else {
                this.$toast.error("Please enter a valid input");
            }
        },

        async fetchResult(isNext, type) {
            this.startLoader(type?.toLowerCase());
            let isFirstCallDone = isNext;
            let conditionCheck = isNext ? this.pagination[type?.toLowerCase()].page === this.pagination[type?.toLowerCase()].total_page && this.pagination[type.toLowerCase()].next : true;

            while (this[`all${type}`].length <= 100 * this.pagination[type?.toLowerCase()].page && conditionCheck) {
                await this[`get${type}`](isFirstCallDone);
                if (!(type == "Followers" ? this.followersExists : this.friendsExists)) {
                    break;
                }
                if (!isFirstCallDone) {
                    isFirstCallDone = true;
                }
                conditionCheck = this.pagination[type?.toLowerCase()].page === this.pagination[type?.toLowerCase()].total_page && this.pagination[type?.toLowerCase()].next;
            }
            this.resetLoader(type?.toLowerCase());
        },

        async getFollowers(isNext) {
            // this.startLoader("followers");
            let data = {
                user_id: this.userDetails?.id_str,
                start: isNext ? this.pagination.followers.next : "",
            };

            const response = await axios.post("/twitter/user/followers", data, {
                headers: {
                    "x-tool-name": this.$route.params.toolName,
                    "x-visited": true,
                    "x-query-id": this.request_id,
                },
            });
            try {
                if (response && response.data && response.data.data?.length >= 0) {
                    if (isNext) {
                        this.allFollowers = this.allFollowers.concat(response.data.data);
                    } else {
                        this.pagination.followers = {
                            page: 1,
                            perPage: 100,
                            total_page: 1,
                            next: "",
                        };
                        this.allFollowers = [];
                        this.allFollowers = response.data.data;
                    }
                    this.csvData.followers = this.convertToCSV(this.allFollowers);

                    this.pagination.followers.next = response.data.next;
                    this.pagination.followers.total_page = parseInt(this.allFollowers.length / this.pagination.followers.perPage, 10);

                    if (this.allFollowers.length % this.pagination.followers.perPage) {
                        this.pagination.followers.total_page += 1;
                    }
                    this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "twitter-tool"});
                    this.followersExists = true;
                } else if (response) {
                    this.followersExists = false;
                    this.$toast.error("No data found");
                }

                // this.resetLoader("followers");
            } catch (error) {
                this.resetLoader("followers");
                let message = error.message;
                this.$toast.error(message);
            }
        },

        async getFriends(isNext) {
            // this.startLoader("friends");
            let data = {
                user_id: this.userDetails?.id_str,
                start: isNext ? this.pagination.friends.next : "",
            };

            const response = await axios.post("/twitter/user/friends", data, {
                headers: {
                    "x-tool-name": this.$route.params.toolName,
                    "x-visited": true,
                    "x-query-id": this.request_id,
                },
            });
            try {
                if (response && response.data && response.data.data?.length >= 0) {
                    if (isNext) {
                        this.allFriends = this.allFriends.concat(response.data.data);
                    } else {
                        this.pagination.friends = {
                            page: 1,
                            perPage: 100,
                            total_page: 1,
                            next: "",
                        };
                        this.allFriends = [];
                        this.allFriends = response.data.data;
                    }
                    this.csvData.friends = this.convertToCSV(this.allFriends);
                    this.pagination.friends.next = response.data.next;
                    this.pagination.friends.total_page = parseInt(this.allFriends.length / this.pagination.friends.perPage, 10);

                    if (this.allFriends.length % this.pagination.friends.perPage) {
                        this.pagination.friends.total_page += 1;
                    }
                    this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "twitter-tool"});
                    this.friendsExists = true;
                } else if (response) {
                    this.$toast.error("No data found");
                    this.friendsExists = false;
                }

                // this.resetLoader("friends");
            } catch (error) {
                this.resetLoader("friends");
                let message = error.message;
                this.$toast.error(message);
            }
        },

        redirectTo() {
            let query = {};
            if (this.request_id)
                query = {
                    query_id: this.request_id,
                };
            this.$router.push({path: this.$route.path, query}).catch((err) => {});
        },

        redirectToQuery(dataRes) {
            //redirect to same query
            if (this.$route?.query?.query_id) {
                let {query_id} = this.$route.query;
                let selected_query = this.toolSearchQueries?.find((el) => el._id === query_id);

                const type = Object.keys(selected_query?.query)[0];
                const value = Object.values(selected_query?.query)[0];

                this.inputValue = {
                    text: startCase(type),
                    value: startCase(type),
                };
                if (startCase(type) === "Username") {
                    this.selectedTab = "details";
                    this.searchKeyword = value;
                    this.selectedItem = {
                        value: value,
                    };
                    this.selectVal = value;
                } else if (startCase(type) === "Hashtag") {
                    this.selectedTab = "tweets";
                    this.hashtag = value;
                } else {
                    this.selectedTab = "details";
                    this.person = value;
                }
            } else {
                this.redirectTo();
            }
        },
        onSelect({value}) {
            this.selectVal = value;
            this.$refs["analysis-tool-input"].search = value;
        },

        close(type) {
            this.selectedItem = {
                value: this.selectVal,
            };
            this.searchKeyword = this.selectVal;
            this.$refs["analysis-tool-input"].search = this.selectVal;
        },
        handleChange(event) {
            if (event != this.selectVal) {
                this.showResult = false;
                this.isSearchClicked = false;
            }
            this.selectVal = event;
        },
        onInput() {
            this.showResult = false;
            this.isSearchClicked = false;
        },
        getMonitoring,
        getBackgroundStatus,
        getResultsID,

        async monitoringSearch(type) {
            let sources = ["twitter-tool"];
            this.isLoading = true;
            let values = await monitoringSearchData(this.case_id, sources, this.$route.params.toolName, this.getMonitoringDateFilter, this.getSelectedToolQuery);
            if (type === "Username") {
                this.userDetailsMonitoring = values;
                // this.userDetailsMonitoring.forEach((userDetails, index) => {
                //     let address = userDetails?.location;
                //     // The location of Uluru
                //     let geocoder = new google.maps.Geocoder();
                //     if (address) {
                //         geocoder.geocode({ address: address }, function(results, status) {
                //             if (results.length > 0) {
                //                 const lat = results[0].geometry.location.lat();
                //                 const lng = results[0].geometry.location.lng();
                //                 const uluru = { lat: lat, lng: lng };
                //                 // The map, centered at Uluru
                //                 const map = new google.maps.Map(document.getElementById("googleMap"), {
                //                     zoom: 12,
                //                     center: uluru,
                //                 });
                //                 // The marker, positioned at Uluru
                //                 const marker = new google.maps.Marker({
                //                     position: uluru,
                //                     map: map,
                //                 });
                //             }
                //         });
                //     }
                // });
                this.showResult = true;
            } else if (type === "Hashtag") {
                this.allTweets = values;
            } else if (type === "Query") {
                this.allUsers = values;
                this.csvData.users = this.convertToCSV(this.allUsers);
                this.showResult = true;
            }
            this.isLoading = false;
        },

        async handleMonitoring(index, user = false) {
            let monitoring_status, card_id;
            if (user) {
                this.users[index].monitoring_status = !this.users[index].monitoring_status;
                card_id = this.users[index]?.doc_id;
                monitoring_status = this.users[index]?.monitoring_status;
            } else {
                this.userDetailsMonitoring[index].monitoring_status = !this.userDetailsMonitoring[index].monitoring_status;
                card_id = this.userDetailsMonitoring[index]?.doc_id;
                monitoring_status = this.userDetailsMonitoring[index]?.monitoring_status;
            }
            this.$forceUpdate();
            await SaasAxios.post("/data-monitoring", {
                product_name: this.getProduct.name,
                product_id: this.getProduct.key,
                case_id: this.$route.params.id,
                query: this.getSelectedToolQuery,
                tool: this.$route.params.toolName,
                frequency: 7,
                monitoring_type: "card",
                inherit_product_settings: false,
                inherit_product_id: "",
                card_id,
                card_source: this.$route.params.toolName,
                status: monitoring_status ? "enabled" : "disabled",
            });
        },

        async handleDone({target}, index, user = false) {
            if (user) {
                this.users[index].changes_viewed = !this.users[index].changes_viewed;
            } else {
                this.userDetailsMonitoring[index].changes_viewed = !this.userDetailsMonitoring[index].changes_viewed;
            }
            this.$forceUpdate();
            let sources = ["twitter-tool"];
            await SaasAxios.post("/data-monitoring/mark-monitered-data-visited", {
                sources,
                doc_id: user ? this.users[index].monitoring_id : this.userDetailsMonitoring[index].monitoring_id,
                visited: target.checked,
            });
        },

        async handleMarkAsAllDone({target}) {
            this.userDetailsMonitoring.forEach((userDetails, index) => {
                this.userDetailsMonitoring[index].changes_viewed = target.checked;
            });
            this.$forceUpdate();
            let sources = ["twitter-tool"];
            await SaasAxios.post("/data-monitoring/mark-monitered-data-visited/query/all", {
                sources: sources,
                mark_all_as_visited: target.checked,
                query_id: this.getSelectedToolQuery.query_id,
            });
        },
        getFormattedTime(ts) {
            let date = DateTime.fromISO(ts);
            return date.toFormat("dd MMM yyyy");
        },
    },
};
